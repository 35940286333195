@import './../../../../scss/theme-bootstrap';

.footer-backtotop {
  #{$rdirection}: 20px;
  position: fixed;
  z-index: 1;
  bottom: 85px;
  top: auto;
  font-size: 35px;
  &:before {
    background: $color-white;
    border-radius: 50%;
  }
  @include breakpoint($landscape-up) {
    #{$ldirection}: auto;
    #{$rdirection}: 44px;
    width: auto;
    height: 62px;
    bottom: 62px;
  }
  &--icon__arrow {
    background: url('#{$base-theme-path}img/icons/src/arrow-white--up.svg') 0 0 no-repeat;
    width: 40px;
    height: 40px;
    display: block;
    background-size: cover;
  }
  .active-panel-wp_samples & {
    bottom: 125px;
  }
}
